import cx from 'classnames';

import Typography from '#app/UI/atoms/new-typography';
import { eventFb } from '#app/hooks/eventFb';
import { eventGtag } from '#app/hooks/eventGtag';
import { useUserContext } from '#hooks/index';
import { event } from '#helpers/gtag';

import styles from './index.module.scss';

const Icon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4479 31.9728L11.0199 32.3118C13.4231 33.738 16.1784 34.4923 18.9883 34.4939H18.9944C27.6233 34.4939 34.6463 27.4724 34.65 18.8429C34.6513 14.6607 33.0246 10.7283 30.0687 7.7702C27.1127 4.81225 23.1823 3.18205 19.0004 3.18033C10.3646 3.18033 3.3418 10.2008 3.33835 18.8301C3.3372 21.7873 4.16446 24.6671 5.73183 27.159L6.10438 27.7513L4.52245 33.5264L10.4479 31.9728ZM0 38L2.67217 28.2428C1.02397 25.3871 0.156872 22.1476 0.158213 18.8289C0.162235 8.44672 8.61164 0 18.9944 0C24.0329 0.00210694 28.762 1.9631 32.3185 5.5221C35.8745 9.0813 37.8322 13.8125 37.8305 18.8441C37.8261 29.2257 29.3753 37.674 18.9944 37.674C18.9937 37.674 18.9948 37.674 18.9944 37.674H18.9864C15.834 37.6727 12.7365 36.8822 9.98536 35.3816L0 38Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2892 10.9599C13.9365 10.1762 13.5655 10.1605 13.2301 10.1467C12.9557 10.135 12.6417 10.1358 12.3282 10.1358C12.0142 10.1358 11.5044 10.2535 11.0732 10.7245C10.6417 11.1955 9.42578 12.3341 9.42578 14.6496C9.42578 16.9653 11.1125 19.2029 11.3475 19.5172C11.5829 19.8309 14.6035 24.7347 19.3872 26.6212C23.363 28.189 24.1721 27.8771 25.0349 27.7986C25.8978 27.7203 27.8196 26.6605 28.2116 25.5614C28.6039 24.4626 28.6039 23.5206 28.4863 23.3238C28.3687 23.1277 28.0548 23.0099 27.5842 22.7747C27.1133 22.5393 24.7995 21.4006 24.3682 21.2435C23.9367 21.0867 23.6229 21.0083 23.309 21.4795C22.9952 21.9501 22.0938 23.0099 21.8192 23.3238C21.5447 23.6384 21.27 23.6776 20.7994 23.4422C20.3286 23.2061 18.8128 22.7096 17.0146 21.1064C15.6154 19.8589 14.6709 18.3183 14.3962 17.8472C14.1218 17.3765 14.3669 17.1216 14.6029 16.887C14.8144 16.6761 15.0737 16.3374 15.3091 16.0628C15.5439 15.7879 15.6223 15.5918 15.7792 15.2778C15.9362 14.9637 15.8577 14.6888 15.7401 14.4534C15.6223 14.218 14.7077 11.8906 14.2892 10.9599Z"
      fill="white"
    />
  </svg>
);

const WhatsApp = ({
  msg,
  number = '17867892469',
  eventNameAnalytics,
  isVisible = true
}) => {
  const { data: userData } = useUserContext();
  const url = msg
    ? `https://api.whatsapp.com/send/?phone=${number}&text=${msg}`
    : `https://api.whatsapp.com/send?phone=${number}&text=Hola%20%F0%9F%98%8A%2C%20quisiera%20informaci%C3%B3n%20sobre%20los%20cursos%20y%20rutas%20de%20Crack%20The%20Code.%20Utilizando%20el%20c%C3%B3digo%20HOMECTC.`;

  const trackingGAEvent = () => {
    event(
      'whatsapp_button_click',
      'customer_contact',
      'Consulta de un cliente por whatsapp'
    );
    if (eventNameAnalytics) {
      eventGtag(eventNameAnalytics, userData);
      eventFb(eventNameAnalytics, userData);
    }
  };

  return (
    <a
      className={cx(styles.button, 'gap-x-12', { [styles.button__visible]: !isVisible })}
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={trackingGAEvent}
    >
      <Icon />

      <Typography className="typo-16 text-white" tag="p">
        ¡Hola! <br /> ¿Te ayudamos?
      </Typography>
    </a>
  );
};

export default WhatsApp;
